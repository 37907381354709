import React, { useState} from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import DonationForm from './DonationForm';
import SubscriptionDonationForm from './SubscriptionDonationForm';

const promise = loadStripe(`${process.env.GATSBY_STRIPE_PUBLISHABLE_KEY}`);

export default function DonationWrapper(props) {
    const options ={
        mode: "subscription",
        amount: 1000,
        currency: "usd",
    }
    return (
        <div>
            <Elements stripe={promise} options={options}>
                <SubscriptionDonationForm amount={props.amount} logo={props.logo} />
            </Elements>
        </div>
    )
}