import React, { useState, useRef, useEffect } from "react";
import lottie from "lottie-web";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Radio,
  RadioGroup,
  VStack,
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  HStack,
  Flex,
  Text,
  Spinner,
  Link,
  Heading,
  Textarea,
  Checkbox,
} from "@chakra-ui/react";
import { BsCheck2Circle } from "react-icons/bs";
import { BsCurrencyDollar, BsAt } from "react-icons/bs";
import { VscWarning, WarningTwoIcon } from "react-icons/vsc";
import StripeLogo from "./stripe-logo.svg";
import Bubbles from "./bubbles";
import styled from "@emotion/styled";
import { GatsbyImage } from "gatsby-plugin-image";

const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Email is required"),
  phone: yup.string().required("Phone is required"),
  address: yup.string().required("Address is required"),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
  zip: yup.string().required("Zip is required"),
});


export default function DonationForm(props) {
  const bearwiseLogo = props.logo;

  
  const {
    register,
    watch,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [amount, setAmount] = useState();

  const stripe = useStripe();
  const elements = useElements();

  const [selectedAmount, setSelectedAmount] = useState(100);  
  const [priceId, setPriceId] = useState("price_1QIBzPLOLhTbuwFA9sNSwNDh"); // Price ID for $100/month

  const watchObject = watch();

  console.log(priceId);

  const errorStyles = {
    color: "#bf1650",
    fontSize: "1rem",
  };

  function RequiredSpan() {
    return <span style={{ color: "#bf1650" }}>*</span>;
  }

  // create a Ref to access our form element
  const formRef = useRef(null);

  const stripeStyle = {
    base: {
      color: "#134053",
      fontFamily: "Open Sans, sans-serif",
      fontSize: "1rem",
    },
    invalid: {
      color: "#bf1650",
      iconColor: "#bf1650",
    },
  };

  // Show any Stripe errors
  const handleChange = async (event) => {
    setError(event.error ? event.error.message : "");
  };


  const addCommaToAmount = (amount) => {
    if (isNaN(amount)) {
      return "";
    } else {
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  };

  const FormSuccess = () => {
    return (
      <Box my="20">
        <VStack>
          <BsCheck2Circle size="4rem" />
          <Heading as="h2" textAlign="left" my="4">
            Thank you for your donation!
          </Heading>
        </VStack>
      </Box>
    );
  };

  const sendFormData = async (event) => {
    if (!formRef.current) {
      console.log("something went wrong with the ref");
    }

    setProcessing(true);

    try {
      const response = await window
      .fetch("/.netlify/functions/create-stripe-subscription", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          amount,
          receipt_email: watchObject.email,
          description: "Website Donation",
          price_id: priceId,
          metadata: {
            anonymous: watchObject.anonymous,
            honor_or_memory: watchObject.honorOrMemory,
            memory_full_name: watchObject.memorialName,
            honoree_full_name: watchObject.honoreeName,
            notification_name: watchObject.notificationName,
            notification_address: watchObject.notificationAddress,
            notification_city: watchObject.notificationCity,
            notification_state: watchObject.notificationState,
            notification_zip: watchObject.notificationZip,
            notification_message: watchObject.notificationMessage,
            include_gift_amount: watchObject.includeAmount,
            donor_name: watchObject.name,
            donor_email: watchObject.email,
            donor_phone: watchObject.phone,
            donor_address: watchObject.address,
            donor_address2: watchObject.address2,
            donor_city: watchObject.city,
            donor_state: watchObject.state,
            donor_zip: watchObject.zip,
          },
        }),
      });

      const responseText = await response.text();
      console.log('Raw response:', responseText);
      
      const data = JSON.parse(responseText);
      console.log('Parsed data:', data);
  
      if (!response.ok) {
        throw new Error(data?.error?.message || 'Something went wrong');
      }
  
      // Check the structure of data before accessing clientSecret
      if (!data?.clientSecret) {
        console.error('Missing clientSecret in response:', data);
        throw new Error('Invalid server response: missing client secret');
      }

      // Confirm the subscription payment with the returned client secret
      const paymentResult = await stripe.confirmCardPayment(data.clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: watchObject.name,
            email: watchObject.email,
          },
        },
      });

      if (paymentResult.error) {
        setError(`Payment failed: ${paymentResult.error.message}`);
        setProcessing(false);
      } else {
        if (paymentResult.paymentIntent.status === "succeeded") {
          setError(null);
          setSucceeded(true);
        }
      }
    }
    catch (err) {
      setError(err.message);
        setProcessing(false);
      }
  }


  return (
    <>
      {succeeded ? (
        <FormSuccess />
      ) : (
        <div>
          <Heading as="h2" mb="4">Make a Monthly Donation</Heading>
          <Text>
          <strong>Examples of annual needs your monthly donations can support:</strong><br />
          $25 - Mugler Plaza Community Space maintenance and activation<br />
          $50 - Supplies for free annual art, music and theater events<br />
          $100 - Streetscape improvement expenses: downtown flowers, seasonal decor and lights, trash cleanups, post office gardens<br />
          $200 - Exhibit development and educational offerings at Teton Geotourism Center<br />
          $500 - Expenses for 75+ annual visiting musicians, artists and actors
          </Text>
          <form
            id="donation-form"
            onSubmit={handleSubmit(sendFormData)}
            onChange={handleChange}
            ref={formRef}
          >
            <Box mb="4">
              <FormLabel>
                Make this donation anonymous?
              </FormLabel>
                <RadioGroup mb="4">
                  <HStack spacing="24px">
                    <Radio value="yes" {...register('anonymous')}>Yes</Radio>
                    <Radio value="no" {...register('anonymous')}>No</Radio>
                  </HStack>
                </RadioGroup>
                </Box>

            <Box mb="12" >
              <FormLabel>
                Is this donation in honor or memory of someone?
              </FormLabel>
                <RadioGroup mb="4">
                  <HStack spacing="24px">
                    <Radio value="yes" {...register('someone')}>Yes</Radio>
                    <Radio value="no" {...register('someone')}>No</Radio>
                  </HStack>
                </RadioGroup>

                {watchObject.someone === 'yes' && (
                  <>
                  <RadioGroup mb="4">
                  <HStack spacing="24px">
                    <Radio value="honor" {...register('honorOrMemory')}>In honor</Radio>
                    <span>or</span>
                    <Radio value="memory" {...register('honorOrMemory')}>In memory</Radio>
                  </HStack>
                </RadioGroup>
                  
                  {watchObject.honorOrMemory === 'honor' && (
                    <>
                    <Box textAlign="left" mb="4">
                    <FormControl>
                        <FormLabel>
                        Honoree Full Name:
                        </FormLabel>
                        <Input
                          size="lg"
                          bg="white"
                          type="text"
                          {...register("honoreeName")}
                          autoComplete="on"
                        />

                        {errors.honoreeName && (
                        <HStack spacing="5px" style={errorStyles}>
                            <WarningTwoIcon /> <Box>{errors.honoreeName?.message}</Box>
                        </HStack>
                        )}
                    </FormControl>
                  </Box>
                  <Heading as="h4" variant="h4" mb="4">
                    Notification Recipient&apos;s Information
                  </Heading>
                  <Box textAlign="left" mb="4">
                    <FormControl>
                        <FormLabel>
                        Notification Recipient&apos;s Full Name:
                        </FormLabel>
                        <Input
                          size="lg"
                          bg="white"
                          type="text"
                          {...register("notificationName")}
                          autoComplete="on"
                        />

                        {errors.notificationName && (
                        <HStack spacing="5px" style={errorStyles}>
                            <WarningTwoIcon /> <Box>{errors.notificationName?.message}</Box>
                        </HStack>
                        )}
                    </FormControl>
                  </Box>
                  <Box textAlign="left" mb="4">
                  <FormControl>
                      <FormLabel>
                      Street Address:
                      </FormLabel>
                      <Input
                      size="lg"
                      bg="white"
                      type="text"
                      {...register("notificationAddress")}
                      autoComplete="on"
                      />

                      {errors.notificationAddress && (
                      <HStack spacing="5px" style={errorStyles}>
                          <WarningTwoIcon /> <Box>{errors.notificationAddress?.message}</Box>
                      </HStack>
                      )}
                  </FormControl>
              </Box>

              <Flex w="100%" flexWrap="wrap">
              <Box textAlign="left" mb="4" w={{base: '100%', lg: '33%'}} pr={{base: 0, lg: 2}}>
                  <FormControl>
                      <FormLabel>
                      City:
                      </FormLabel>
                      <Input
                      size="lg"
                      bg="white"
                      type="text"
                      {...register("notificationCity")}
                      autoComplete="on"
                      />

                      {errors.notificationCity && (
                      <HStack spacing="5px" style={errorStyles}>
                          <WarningTwoIcon /> <Box>{errors.notificationCity?.message}</Box>
                      </HStack>
                      )}
                  </FormControl>
              </Box>
              <Box textAlign="left" mb="4" w={{base: '100%', lg: '33%'}}>
                  <FormControl>
                      <FormLabel>
                      State:
                      </FormLabel>
                      <Input
                      size="lg"
                      bg="white"
                      type="text"
                      {...register("notificationState")}
                      autoComplete="on"
                      />

                      {errors.notificationState && (
                      <HStack spacing="5px" style={errorStyles}>
                          <WarningTwoIcon /> <Box>{errors.notificationState?.message}</Box>
                      </HStack>
                      )}
                  </FormControl>
              </Box>
              <Box textAlign="left" mb="4" w={{base: '100%', lg: '33%'}} pl={{base: 0, lg: 2}}>
                  <FormControl>
                      <FormLabel>
                      Zip:
                      </FormLabel>
                      <Input
                      size="lg"
                      bg="white"
                      type="text"
                      {...register("notificationZip")}
                      autoComplete="on"
                      />

                      {errors.notificationZip && (
                      <HStack spacing="5px" style={errorStyles}>
                          <WarningTwoIcon /> <Box>{errors.notificationZip?.message}</Box>
                      </HStack>
                      )}
                  </FormControl>
              </Box>
              </Flex>
              <Box textAlign="left" mb="4">
                  <FormControl>
                      <FormLabel>
                      Message:
                      </FormLabel>
                      <Textarea
                      size="lg"
                      bg="white"
                      type="textarea"
                      
                      {...register("notificationMessage")}
                      autoComplete="on"
                      />

                      {errors.notificationMessage && (
                      <HStack spacing="5px" style={errorStyles}>
                          <WarningTwoIcon /> <Box>{errors.notificationMessage?.message}</Box>
                      </HStack>
                      )}
                  </FormControl>
              </Box>
              <Box mb="4">
              <FormControl>
                <FormLabel display="inline">
                    Include the gift amount in the message?
                </FormLabel>
                <Checkbox {...register('includeAmount')} value="yes">
                    Yes
                </Checkbox>
            </FormControl>
              </Box>
              </>
                  )}

                  {watchObject.honorOrMemory === 'memory' && (
                    <Box textAlign="left" mb="8">
                    <FormControl>
                        <FormLabel>
                        Full Name of Person Being Memorialized:
                        <RequiredSpan />
                        </FormLabel>
                        <Input
                          size="lg"
                          bg="white"
                          type="text"
                          {...register("memorialName")}
                          autoComplete="on"
                        />

                        {errors.name && (
                        <HStack spacing="5px" style={errorStyles}>
                            <WarningTwoIcon /> <Box>{errors.name?.message}</Box>
                        </HStack>
                        )}
                    </FormControl>
                  </Box>
                  )}
              </>
                )}
                

            </Box>
            <Box textAlign="left" mb="8">
            <Heading as="h4" variant="h4" mb="4" textAlign="center">
              Your Information
            </Heading>
              <FormControl>
                <FormLabel>
                  Name:
                  <RequiredSpan />
                </FormLabel>
                <Input
                  size="md"
                  type="text"
                  {...register("name")}
                  autoComplete="on"
                />

                {errors.name && (
                  <HStack spacing="5px" style={errorStyles}>
                    <VscWarning /> <Box>{errors.name?.message}</Box>
                  </HStack>
                )}
              </FormControl>
            </Box>

            <Flex w="100%" flexWrap="wrap">
              <Box
                textAlign="left"
                mb="8"
                w={{ base: "100%", lg: "50%" }}
                pr={{ base: 0, lg: 2 }}
              >
                <FormControl>
                  <FormLabel>
                    Email:
                    <RequiredSpan />
                  </FormLabel>
                  <Flex>
                    <Flex
                      alignItems="center"
                      borderLeftRadius="md"
                      border="1px solid #E2E8F0"
                      bg="gray.100"
                      px="4"
                      borderRight="none"
                    >
                      <span>
                        <BsAt fill="#4A5568" />
                      </span>
                    </Flex>
                    <Input
                      size="md"
                      type="text"
                      {...register("email")}
                      autoComplete="on"
                      borderLeftRadius="0"
                    />
                  </Flex>

                  {errors.email && (
                    <HStack spacing="5px" style={errorStyles}>
                      <VscWarning /> <Box>{errors.email?.message}</Box>
                    </HStack>
                  )}
                </FormControl>
              </Box>
              <Box
                textAlign="left"
                mb="8"
                w={{ base: "100%", lg: "50%" }}
                pl={{ base: 0, lg: 2 }}
              >
                <FormControl>
                  <FormLabel>
                    Phone:
                    <RequiredSpan />
                  </FormLabel>
                  <Input
                    size="md"
                    type="text"
                    {...register("phone")}
                    autoComplete="on"
                  />

                  {errors.phone && (
                    <HStack spacing="5px" style={errorStyles}>
                      <VscWarning /> <Box>{errors.phone?.message}</Box>
                    </HStack>
                  )}
                </FormControl>
              </Box>
            </Flex>

            <Box textAlign="left" mb="8">
              <FormControl>
                <FormLabel>
                  Street Address:
                  <RequiredSpan />
                </FormLabel>
                <Input
                  size="md"
                  type="text"
                  {...register("address")}
                  autoComplete="on"
                />

                {errors.address && (
                  <HStack spacing="5px" style={errorStyles}>
                    <VscWarning /> <Box>{errors.address?.message}</Box>
                  </HStack>
                )}
              </FormControl>
            </Box>

            <Flex w="100%" flexWrap="wrap">
              <Box
                textAlign="left"
                mb="8"
                w={{ base: "100%", lg: "33%" }}
                pr={{ base: 0, lg: 2 }}
              >
                <FormControl>
                  <FormLabel>
                    City:
                    <RequiredSpan />
                  </FormLabel>
                  <Input
                    size="md"
                    type="text"
                    {...register("city")}
                    autoComplete="on"
                  />

                  {errors.city && (
                    <HStack spacing="5px" style={errorStyles}>
                      <VscWarning /> <Box>{errors.city?.message}</Box>
                    </HStack>
                  )}
                </FormControl>
              </Box>
              <Box textAlign="left" mb="8" w={{ base: "100%", lg: "33%" }}>
                <FormControl>
                  <FormLabel>
                    State:
                    <RequiredSpan />
                  </FormLabel>
                  <Input
                    size="md"
                    type="text"
                    {...register("state")}
                    autoComplete="on"
                  />

                  {errors.state && (
                    <HStack spacing="5px" style={errorStyles}>
                      <VscWarning /> <Box>{errors.state?.message}</Box>
                    </HStack>
                  )}
                </FormControl>
              </Box>
              <Box
                textAlign="left"
                mb="8"
                w={{ base: "100%", lg: "33%" }}
                pl={{ base: 0, lg: 2 }}
              >
                <FormControl>
                  <FormLabel>
                    Zip:
                    <RequiredSpan />
                  </FormLabel>
                  <Input
                    size="md"
                    type="text"
                    {...register("zip")}
                    autoComplete="on"
                  />

                  {errors.zip && (
                    <HStack spacing="5px" style={errorStyles}>
                      <VscWarning /> <Box>{errors.zip?.message}</Box>
                    </HStack>
                  )}
                </FormControl>
              </Box>
            </Flex>
            <Box w="75%">
              <FormLabel>
                Donation Amount:
                <RequiredSpan />
              </FormLabel>
              <RadioGroup
                defaultValue='price_1QIBzPLOLhTbuwFA9sNSwNDh'
                // onChange={setAmount}
                // value={amount}
                position="relative"
                zIndex="1"
              >
                <Flex
                  w="100%"
                  flexWrap="nowrap"
                  justifyContent="space-between"
                  gap="4"
                >
                  <Box position="relative" mb="2">
                    <Radio value="price_1QIBzTLOLhTbuwFAV3VA9nEi" onChange={() => {setSelectedAmount(25); setPriceId("price_1QIBzTLOLhTbuwFAV3VA9nEi")}}>$25</Radio>
                  </Box>
                  <Box position="relative" mb="2">
                    <Radio value="price_1QIBzRLOLhTbuwFAc283Q6xg" onChange={() => {setSelectedAmount(50); setPriceId("price_1QIBzRLOLhTbuwFAc283Q6xg")}}>$50</Radio>
                  </Box>
                  <Box position="relative" mb="2">
                    <Radio value="price_1QIBzPLOLhTbuwFA9sNSwNDh" onChange={() => {setSelectedAmount(100); setPriceId("price_1QIBzPLOLhTbuwFA9sNSwNDh")}}>$100</Radio>
                  </Box>
                  <Box position="relative" mb="2">
                    <Radio value="price_1QIBzNLOLhTbuwFADeYt9kDs" onChange={() => {setSelectedAmount(250); setPriceId("price_1QIBzNLOLhTbuwFADeYt9kDs")}}>$250</Radio>
                  </Box>
                  <Box position="relative" mb="2">
                    <Radio value="price_1QIBzGLOLhTbuwFA03JkjnN3" onChange={() => {setSelectedAmount(500); setPriceId("price_1QIBzGLOLhTbuwFA03JkjnN3")}}>$500</Radio>
                  </Box>
                </Flex>
              </RadioGroup>
            </Box>
            <Box my="4">
              <Text textAlign="left">
                Contact{" "}
                <a href="mailto:fallon@downtowndriggs.org">
                  fallon@downtowndriggs.org
                </a>{" "}
                if you would like to donate an amount not listed here.
              </Text>
            </Box>

            <Box mb="2">
              <FormLabel>
                Credit Card Information:
                <RequiredSpan />
              </FormLabel>
              <CardElement options={{ style: stripeStyle }} />
            </Box>
            {error && (
              <div style={errorStyles} role="alert">
                <HStack spacing="5px">
                  <VscWarning />
                  <p className="card-error">{error}</p>
                </HStack>
              </div>
            )}
            <Flex w="100%" h="100%" alignItems="center">
              <Box mr="1">
                <Text fontSize="0.8rem" mb="0">
                  Payments securely processed by
                </Text>
              </Box>
              <Box>
                <Link
                  href="https://stripe.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={StripeLogo}
                    alt="Stripe logo"
                    style={{ width: "2.5rem" }}
                  />
                </Link>
              </Box>
            </Flex>
            <Box my="6">
              <Button
                type="submit"
                textAlign="center"
                disabled={processing}
                isLoading={processing}
                size="lg"
              >
                {processing ? (
                  <Box
                    display="flex"
                    flexDirection="row"
                    height="100%"
                    alignItems="center"
                  >
                    <Box>Processing...</Box>
                    <Spinner />
                  </Box>
                ) : succeeded ? (
                  "Submitted!"
                ) : (
                  <>Donate ${addCommaToAmount(selectedAmount)}/month</>
                )}
              </Button>
              {error && (
                <div style={errorStyles} role="alert">
                  <HStack spacing="5px">
                    <VscWarning />
                    <p className="card-error">{error}</p>
                  </HStack>
                </div>
              )}
            </Box>
          </form>
        </div>
      )}
    </>
  );
}
